/******* Customized Bootstrap ********/

:root {
  --primary: #050C9C;
  --secondary: #3572EF;

  --light: #F6F6FA;
  --white:#fff;
  --dark: #353f4f;
  --font-family-base: 'Rubik', sans-serif;
  --headings-font-family: 'Plus Jakarta Sans', sans-serif;
  --body-color: #757575;
  --headings-color: #353f4f; /* same as --dark */
  --headings-font-weight: 500;
  --display-font-weight: 700;
  --border-radius: 10px;
  --border-radius-sm: 10px; /* same as --border-radius */
  --border-radius-lg: 10px; /* same as --border-radius */
  --link-decoration: none;
}

/* General styles */
body {
  font-family: var(--font-family-base);
  color: var(--body-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--headings-font-family);
  color: var(--headings-color);
  font-weight: var(--headings-font-weight);
}

.display-1, .display-2, .display-3, .display-4 {
  font-weight: var(--display-font-weight);
}

/* Borders */
.border-radius {
  border-radius: var(--border-radius);
}

.border-radius-sm {
  border-radius: var(--border-radius-sm);
}

.border-radius-lg {
  border-radius: var(--border-radius-lg);
}

/* Links */
a {
  text-decoration: var(--link-decoration);
}

/* Colors */
.bg-primary {
  background-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.bg-light {
  background-color: var(--light) !important;
}

.text-light {
  color: var(--light) !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.text-dark {
  color: var(--dark) !important;
}















/*** Spinner Start ***/
/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}
/*** Spinner End ***/

.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  z-index: 99;
}

/*** Button Start ***/
.btn {
  font-weight: 600;
  transition: .5s;
}

.btn-square {
  width: 32px;
  height: 32px;
}
.center-icon i {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-sm-square {
  width: 34px;
  height: 34px;
}

.btn-md-square {
  width: 44px;
  height: 44px;
}

.btn-lg-square {
  width: 56px;
  height: 56px;
}

.btn-square,
.btn-sm-square,
.btn-md-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50%;
}

.btn.btn-primary {
  background: var(--primary) !important;
  color: var(--white) !important;
  font-weight: 400;
  transition: 0.5s;
}

.btn.btn-primary:hover {
  background: var(--white) !important;
  color: var(--primary) !important;
}

.btn.btn-light {
  background: var(--light) !important;
  color: var(--primary) !important;
  font-weight: 400;
  transition: 0.5s;
}

.btn.btn-light:hover {
  background: var(--primary) !important;
  color: var(--light) !important;
}


.RotateMoveLeft {
  position: relative;
  animation: RotateMoveLeft 10s linear infinite;
}

.RotateMoveRight {
  position: relative;
  animation: RotateMoveLeft 10s linear infinite;
}

@keyframes RotateMoveLeft {
  0%   {left: 0px;}
  50%   {left: 40px;}
  100%  {left: 0px;}
}

@keyframes RotateMoveRight {
  0%   {right: 0px;}
  50%   {right: 40px;}
  100%  {right: 0px;}
}


/*** Navbar ***/
.navbar-light .navbar-nav .nav-link {
  margin-right: 25px;
  padding: 35px 0;
  color: var(--dark) !important;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  transition: .5s;
}



.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary) !important;
}

.navbar-light .navbar-brand img {
  max-height: 100px;
  transition: .5s;
}

.sticky-top.navbar-light .navbar-brand img {
  max-height: 100px;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  vertical-align: middle;
  margin-left: 8px;
}

@media (min-width: 1200px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      visibility: hidden;
      top: 100%;
      transform: rotateX(-75deg);
      transform-origin: 0% 0%;
      border: 0;
      border-radius: 10px;
      transition: .5s;
      opacity: 0;
  }
}

.dropdown .dropdown-menu a:hover {
  background: var(--primary);
  color: var(--white);
}

.navbar .nav-item:hover .dropdown-menu {
  transform: rotateX(0deg);
  visibility: visible;
  background: var(--light) !important;
  transition: .5s;
  opacity: 1;
}

.collapse.navbar-collapse {
  transition: 0.5s;
}

@media (max-width: 992px) {
  nav.bg-transparent {
      position: absolute;
      width: 100%;
      background: transparent !important;
  }

  .navbar.navbar-expand-lg button span {
      position: relative;
      z-index: 99;
  }

  .navbar {
      position: relative;
      background: var(--light);
      z-index: 2;
  }

  .sticky-top.navbar-light {
      position: relative;
      background: var(--white) !important;
      padding: 0 20px 20px 20px !important;
  }

  .sticky-top.navbar-light {
      background: transparent;
  }

  nav.navbar {
      padding: 0 30px 30px 0;
  }

  .navbar.navbar-expand-lg .navbar-toggler {
      padding: 10px 20px;
      border: 1px solid var(--primary);
      color: var(--primary);
  }

  .navbar-light .navbar-collapse {
      margin-top: 15px;
      border-top: 1px solid #DDDDDD;
  }

  .navbar-light .navbar-nav .nav-link,
  .sticky-top.navbar-light .navbar-nav .nav-link {
      padding: 10px 0;
      margin-left: 0;
      color: var(--dark) !important;
  }


  .navbar-light .navbar-brand img {
      max-height: 45;
  }

  .hero-header {
      margin-top: -100px !important;
  }
}

@media (min-width: 992px) {
  .navbar-light {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background: transparent !important;
      z-index: 999;
  }
  
  .sticky-top.navbar-light {
      position: fixed;
      background: var(--light) !important;
  }
}
@media (max-width: 430px) {
  .navbar-light .navbar-brand img {
    max-height: 80px;
    transition: .5s;
  }
  
  .sticky-top.navbar-light .navbar-brand img {
    max-height: 80px;
  }
}
/*** Navbar End ***/

/*** Single Page Hero Header Start ***/
.bg-breadcrumb {
  position: relative;
  overflow: hidden;
  background: linear-gradient(rgba(102, 16, 242, 0.05), rgba(102, 16, 242, 0.05));
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0 60px 0;
  margin-bottom: 6rem;
  transition: 0.5s;
}

.bg-breadcrumb::after {
  content: "";
  position: absolute;
  bottom: -20%;
  left: -10%;
  width: 600px;
  height: 600px;
  border-radius: 300px;
  border: 80px solid rgba(247, 71, 128, 0.05);
  background: transparent;
  animation: RotateMoveSingle 5s linear infinite;
  z-index: -1;
}

.bg-breadcrumb .breadcrumb {
  position: relative;
  z-index: 2;
}


@keyframes RotateMoveSingle {
  0% {
      -webkit-transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
      transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }
    100% {
      -webkit-transform: rotateZ(360deg) rotate(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
      transform: rotateZ(360deg) rotate(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
  }

  0% {bottom: 0px;}
  50%   {left: -10px;}
  75%   {bottom: 10%;}
  100%  {bottom: 0px;}
}

.bg-breadcrumb::before {
  content: "";
  position: absolute;
  top: -15%;
  right: -10%;
  width: 600px;
  height: 600px;
  border-radius: 300px;
  border: 80px solid rgba(247, 71, 128, 0.04);
  background: transparent;
  animation: RotateMoveSingle 5s linear infinite;
  z-index: -1;
}

@keyframes RotateMoveSingle {
  0% {
      -webkit-transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
      transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }
    100% {
      -webkit-transform: rotateZ(360deg) rotate(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
      transform: rotateZ(360deg) rotate(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
  }

  0% {top: 0px;}
  50%   {top: 10%;}
  100%  {top: 0px;}
}

@media (max-width: 992px) {
  .bg-breadcrumb {
      padding-top: 100px !important;
  }
}

.bg-breadcrumb .breadcrumb-item a {
  color: var(--dark) !important;
}


.breadcrumb-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.breadcrumb-animation li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(102, 16, 242, 0.07);
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.breadcrumb-animation li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.breadcrumb-animation li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.breadcrumb-animation li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.breadcrumb-animation li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.breadcrumb-animation li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.breadcrumb-animation li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.breadcrumb-animation li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.breadcrumb-animation li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.breadcrumb-animation li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.breadcrumb-animation li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}
/*** Single Page Hero Header End ***/


/*** Hearo Header Start ***/
.header::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  background: var(--light);
  border-radius: 22% 78% 33% 67% / 32% 0% 100% 68%;
  animation: bgMove 5s linear infinite;
  z-index: -1;
}

@keyframes bgMove {
  0%   {right: 0px;}
  50%   {right: 20px;}
  100%  {right: 0px;}
}

.header::before {
  content: "";
  position: absolute;
  bottom: -9%;
  left: -7%;
  width: 400px;
  height: 400px;
  border-radius: 200px;
  border: 30px solid rgba(247, 71, 128, 0.05);
  background: transparent;
  animation: RotateMoveHeader 5s linear infinite;
  z-index: -1;

}

@keyframes RotateMoveHeader {
  0% {
      -webkit-transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
      transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }
    100% {
      -webkit-transform: rotateZ(360deg) rotate(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
      transform: rotateZ(360deg) rotate(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
  }


  0% {bottom: 0px;}
  50%   {left: -10px;}
  75%   {bottom: 9%;}
  100%  {bottom: 0px;}
}

.hero-header {
  padding-top: 160px;
  padding-bottom: 100px;
  position: relative;
}

.hero-header::after {
  content: "";
  position: absolute;
  left: 100px;
  bottom: 100px;
  width: 58px;
  height: 50px;
  background: url(./img/sty-1.png) center center no-repeat;
  animation: RotateMoveSty-3 45s linear infinite;
  transition: 0.5s;
  z-index: -1;
  
}

@keyframes RotateMoveSty-3 {
  0%   {left: 100px;}
  40%   {bottom: -0px;}
  50%   {left: 700px;}
  70%   {bottom: 500px;}
  80%   {left: 400px;}
  95%   {bottom: -0px;}
  100%  {left: 100px;}
}

.hero-header::before {
  content: "";
  position: absolute;
  left: 100px;
  bottom: 100px;
  width: 300px;
  height: 300px;
  border-radius: 150px;
  border: 30px solid rgba(102, 16, 242, 0.05);
  background: transparent;
  animation: RotateMoveSty-4 45s linear infinite;
  transition: 0.5s;
  z-index: -1;
  
}

@keyframes RotateMoveSty-4 {
  0%   {left: 100px;}
  40%   {bottom: -0px;}
  50%   {left: 700px;}
  70%   {bottom: 500px;}
  80%   {left: 400px;}
  95%   {bottom: -0px;}
  100%  {left: 100px;}
}


.hero-header .rotate-img {
  position: absolute;
  top: 70px;
  left: 20px;
}

.hero-header .rotate-img .rotate-sty-2 {
  position: absolute;
  top: 100px;
  left: 50px;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  border: 5px solid rgba(247, 71, 128, 0.1);
  background: transparent;
  animation: RotateMoveSty-2 45s linear infinite;
  transition: 0.5s;

}

@keyframes RotateMoveSty-2 {
  0%   {left: 0px;}
  40%   {top: -30px;}
  50%   {left: 500px;}
  70%   {top: 200px;}
  80%   {left: 100px;}
  95%   {top: -30px;}
  100%  {left: 0px;}
}

.hero-header .rotate-img img {
  position: relative;
  animation: RotateMove 30s linear infinite;
  z-index: -1;
}

@keyframes RotateMove {
  0%   {left: 0px;}
  50%   {left: 200px;}
  100%  {left: 0px;}
}


@media (max-width: 992px) {
  .hero-header {
      padding-top: 280px;
      
  }

  .hero-header .rotate-img img {
      margin-top: 100px;
  }
 
}
/*** Hero Header End ***/


/*** Service Start ***/
.service .service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.1);
}

.service .service-item,
.service .service-item .service-icon,
.service .service-item a {
  transition: 0.5s;
}

.service .service-item:hover {
  background: rgba(102, 16, 242, 0.09);
  border: 1px ;
}


.service .service-item:hover .service-icon,
.service .service-item:hover a {
  background: var(--white) !important;
}

.service .service-item:hover a:hover {
  background: var(--primary) !important;
  color: var(--white);
}

/*** Service End ***/


/*** Features Start ***/
.feature .feature-img {
  background: var(--light);
  border-radius: 58% 42% 21% 79% / 30% 29% 71% 70%;
}
/*** Features End ***/


/*** Pricing Start ***/
.price .price-item {
  position: relative;
  overflow: hidden;
  transition: 0.5s;
}

.price .price-item:hover {
  background: var(--white) !important;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.2);
}

.price .price-item .pice-item-offer {
  position: absolute;
  width: 200px;
  height: 110px;
  top: -45px;
  right: -80px;
  background: var(--primary) !important;
  color: var(--white);
  transform: rotate(42deg);
  display: flex;
  align-items: end;
  justify-content: center;
  padding-bottom: 10px;
}
/*** Pricing End ***/


/*** Blog Start ***/
.blog .blog-item {
  border-radius: 10px;
}

.blog .blog-item .blog-img {
  position: relative;
  overflow: hidden;
  background: rgba(102, 16, 242, 0.2);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.blog .blog-item .blog-img .blog-info {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  padding: 20px;
  background: rgba(102, 16, 242, 0.2);
  color: var(--white) !important;
  display: flex;
  align-items: end;
  justify-content: space-between;
  transition: 0.5s;
}

.blog .blog-item .blog-img:hover .blog-info {
  background: rgba(0, 0, 0, .4);
}


.blog .blog-item .blog-content {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.blog .blog-item .blog-img img {
  transition: 0.5s;
}

.blog .blog-item .blog-img:hover img {
  transform: scale(1.3);
}
/*** Blog End ***/


/*** Testimonial Start ***/
.testimonial {
  position: relative;
  background: var(--light);
  overflow: hidden;
  z-index: 1;
}
.testimonial::after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: url(./img/testimonial-img-2.jpg) center center no-repeat;
  animation: TestimonialMoveLeft 100s linear infinite;
  z-index: -1;

}

.testimonial::before {
  content: "";
  position: absolute;
  top: 30%;
  right: 0;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: url(./img/testimonial-img-3.jpg) center center no-repeat;
  animation: TestimonialMoveRight 100s linear infinite;
  z-index: -1;

}

@keyframes TestimonialMoveLeft {
  0%   {left: 0px;}
  25%   {top: 100px;}
  50%   {left: 100%;}
  75%   {top: 80%;}
  100%  {left: 0px;}
}

@keyframes TestimonialMoveRight {
  0%   {right: 0px;}
  25%   {top: 100px;}
  50%   {right: 100%;}
  75%   {top: 80%;}
  100%  {right: 0px;}
}

.testimonial .testimonial-carousel.owl-carousel {
  position: relative;
  padding: 0 35px;
  transition: 0.5s;
}

.testimonial .testimonial-carousel .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 65px;
  height: 65px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(102, 16, 242, 0.09);
  color: var(--primary);
  transition: 0.5s;
}

.testimonial .testimonial-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 65px;
  height: 65px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(102, 16, 242, 0.09);
  color: var(--primary);
  transition: 0.5s;
}


.testimonial .testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial .testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  color: var(--white) !important;
}


.testimonial-carousel .owl-dots {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dots .owl-dot img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dots .owl-dot.active img {
  width: 70px;
  height: 70px;
  border-radius: 40px;
  border: 4px solid var(--secondary);
}

@media (min-width: 900px) {
  .testimonial .testimonial-carousel .testimonial-item .testimonial-inner p.fs-7 {
      font-size: 20px;
  }
}

@media (max-width: 576px) {
  .testimonial .testimonial-carousel.owl-carousel {
      padding: 0;
  }

  .testimonial .testimonial-carousel .owl-nav .owl-prev {
      margin-top: -250px;
      margin-left: -15px;
  }

  .testimonial .testimonial-carousel .owl-nav .owl-next {
      margin-top: -250px;
      margin-right: -15px;
  }
}
/*** Testimonial End ***/

/*** Contact Start ***/
.contact {
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  z-index: 1;
}






/*** Contact End ***/


/*** Footer Start ***/
.footer {
  background: linear-gradient(rgba(102, 16, 242, 0.05), rgba(102, 16, 242, 0.05));
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer .footer-item a {
  line-height: 35px;
  color: var(--dark);
  transition: 0.5s;
}

.footer .footer-item p {
  line-height: 35px;
}

.footer .footer-item a:hover {
  letter-spacing: 2px;
  color: var(--primary);
}

/*** Footer End ***/




